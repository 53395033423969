.sections {
  text-align: center;
  position : absolute;
  bottom   : 0%;
  width    : 100%;
}


.sectionLinks {
  color : #f00;
  margin-left : 10px;
  margin-right : 10px;
}


a:active {
  opacity: 50%;
}


